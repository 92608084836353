import React,{useEffect} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"




const Datenschutz = () => {

  useEffect(() => {
   
  })

  return(
    <Layout>
    <SEO title="Datenschutzerklärung" />

    <div className="my-32">
        <h1 className="text-4xl text-soYellow my-4">Datenschutzerklärung</h1>
        <p>
        Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. Wir verarbeiten Ihre Daten daher ausschließlich auf Grundlage der gesetzlichen Bestimmungen (DSGVO, TKG 2003). In diesen Datenschutzinformationen informieren wir Sie über die wichtigsten Aspekte der Datenverarbeitung im Rahmen unserer Website. 
Beim Besuch unserer Webseite wird Ihre IP-Adresse, Beginn und Ende der Sitzung für die Dauer dieser Sitzung erfasst. Dies ist technisch bedingt und stellt damit ein berechtigtes Interesse iSv Art 6 Abs 1 lit f DSGVO dar. Soweit im Folgenden nichts anderes geregelt wird, werden diese Daten von uns nicht weiterverarbeitet.
Kontakt mit uns
Wenn Sie per Formular auf der Website oder per E-Mail Kontakt mit uns aufnehmen, werden Ihre angegebenen Daten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen sechs Monate bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter. 
Cookies
Unsere Website verwendet so genannte Cookies. Dabei handelt es sich um kleine Textdateien, die mit Hilfe des Browsers auf Ihrem Endgerät abgelegt werden. Sie richten keinen Schaden an.
Wir nutzen Cookies dazu, unser Angebot nutzerfreundlich zu gestalten. Einige Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Sie ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.
Wenn Sie dies nicht wünschen, so können Sie Ihren Browser so einrichten, dass er Sie über das Setzen von Cookies informiert und Sie dies nur im Einzelfall erlauben.
Bei der Deaktivierung von Cookies kann die Funktionalität unserer Website eingeschränkt sein.
        </p>
        <h2 className="text-2xl my-4 mt-6">Web-Analyse</h2>
        <p>Nutzung von Google Analytics
Wir verwenden Google Analytics, um die Website-Nutzung zu analysieren. Die daraus gewonnenen Daten werden genutzt, um unsere Website sowie Werbemaßnahmen zu optimieren. 
Google Analytics ist ein Webanalysedienst, der von Google Inc. (1600 Amphitheatre Parkway, Mountain View, CA 94043, United States) betrieben und bereitgestellt wird. Google verarbeitet die Daten zur Website-Nutzung in unserem Auftrag und verpflichtet sich vertraglich zu Maßnahmen, um die Vertraulichkeit der verarbeiteten Daten zu gewährleisten.
Während Ihres Website-Besuchs werden u.a. folgende Daten aufgezeichnet:</p>
<ul className="list-disc ml-8">
    <li>Aufgerufene Seiten</li>
    <li>Bestellungen inkl. des Umsatzes und der bestellten Produkte</li>
    <li>Die Erreichung von "Website-Zielen" (z.B. Kontaktanfragen)</li>
    <li>Ihr Verhalten auf den Seiten (beispielsweise Klicks, Scroll-Verhalten und Verweildauer</li>
    <li>Ihr ungefährer Standort (Land und Stadt)</li>
    <li>Ihre IP-Adresse (in gekürzter Form, sodass keine eindeutige Zuordnung möglich ist)</li>
    <li>Technische Informationen wie Browser, Internetanbieter, Endgerät und Bildschirmauflösung</li>
    <li>Herkunftsquelle Ihres Besuchs (d.h. über welche Website bzw. über welches Werbemittel Sie zu uns gekommen sind)</li>
</ul>
<p>
Diese Daten werden an einen Server von Google in den USA übertragen. Google beachtet dabei die Datenschutzbestimmungen des „EU-US Privacy Shield“-Abkommens.
Google Analytics speichert Cookies in Ihrem Webbrowser für die Dauer von zwei Jahren seit Ihrem letzten Besuch. Diese Cookies enthaltene eine zufallsgenerierte User-ID, mit der Sie bei zukünftigen Website-Besuchen wiedererkannt werden können.
Die aufgezeichneten Daten werden zusammen mit der zufallsgenerierten User-ID gespeichert, was die Auswertung pseudonymer Nutzerprofile ermöglicht. Diese nutzerbezogenen Daten werden automatisch nach 14 Monaten gelöscht. Sonstige Daten bleiben in aggregierter Form unbefristet gespeichert.
Sollten Sie mit der Erfassung nicht einverstanden sein, können Sie diese mit der einmaligen Installation des Browser-Add-ons zur Deaktivierung von Google Analytics unterbinden.
quelle: traffic3.net
</p>

<h2 className="text-2xl my-4 mt-6">
Ihre Rechte
</h2>
<p>
Ihnen stehen bezüglich Ihrer bei uns gespeicherten Daten grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch zu. Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich bei oder der Datenschutzbehörde beschweren.
Sie erreichen uns unter folgenden Kontaktdaten: 
</p>
<br />
<p>
<span className="font-medium">
stepone creative studio<br/>

</span> 
Florian Mende - Einzelunternehmen <br/>

Finkengasse 25, 2514 Traiskirchen<br/>
E-Mail: studio@stepone.at<br/>
Telefon: +43 664 217 56 51
</p>

    </div>

  </Layout>
  )
  
}

export default Datenschutz
